<template>
    <div id="business-list">
        <section class="container py-5">
            <p class="lead">
                本公司多年来一直致力于深耕广东珠三角地区，并逐步向国内具备经济活力的热点城市和地区发展，目前已有项目分布于广州、珠海、佛山、湛江和南宁等地，截止2017年底，公司总资产约30亿人民币。
            </p>
        </section>
        <section class="business row no-gutters">
            <div class="col-sm-6" v-for="v in business" :key="v.title">
                <div class="card">
                    <img class="card-img" alt="背景" :src="require('@a/' + v.cover)">
                    <div class="card-img-overlay">
                        <h3 class="card-title">{{ v.title }}</h3>
                        <p class="card-text lead">{{ v.subtitle }}</p>
                        <button type="button" class="btn btn-outline-light btn-round" 
                            @click="onDetail(v.content)">查看详情</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { business } from '@js/common';

export default {
    name: 'List',
    data() {
        return {
            business
        };
    },
    methods: {
        onDetail(path) {
            this.$router.push({
                name: 'business-detail',
                query: { path }
            });
        }
    }
};
</script>

<style lang="less">
@import '../../style/mixin';

#business-list {
    .business {
        position: relative;
        margin-bottom: 5rem;

        .card {
            border: none;
            .card-img {
                border-radius: 0;
            }
            .card-img-overlay {
                color: white;
                z-index: 3;
                top: 50%;
                bottom: auto;
                text-align: center;
                transform: translateY(-50%);
                .lead {
                    opacity: 0.8;
                    .m-y(1.5rem);
                }
                .btn-round {
                    border-radius: 2rem;
                    .p-x(1.5rem);
                }
            }
        }

        &:after {
            .mask();
        }
    }
}
</style>